@import "../../../../styles//helper.scss";

.account-approval-banner{
  padding: 10px 20px;
  background-color: $blue;
  color: $white;
  top: 0;
  width: 100%;
  text-align: center;
  z-index: 100;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  .close-icon {
    float: right;
    cursor: pointer;
    font-size: 20px;
    line-height: normal;
  }
  .informationIcon{
    font-size: 32px;
  }
}
