@import "styles/helpers";

.overlay {
  background-color: hsla(0, 0%, 0%, 0.3);

  &--show {
    display: block;
  }
}

@mixin l {
  @media only screen and (max-width: "1179px") {
    @content;
  }
}

@mixin t {
  @media only screen and (max-width: 1023px) {
    @content;
  }
}

@mixin m {
  @media only screen and (max-width: "767px") {
    @content;
  }
}

@mixin d {
  @at-root :global(.dark-mode) & {
    @content;
  }
}

@mixin d {
  @at-root .dark-mode {
    @content;
  }
}

@mixin d {
  @at-root .dark-mode & {
    @content;
  }
}

.drawer {
  height: 100vh;
  background-color: var(--color-bg-100-light);
  position: fixed;
  top: 0px;
  bottom: 0px;
  z-index: 999999;
  left: -300px;
  transition: 240ms ease-in-out;
  width: 300px;
  display: flex;
  flex-direction: column;

  &--open {
    left: 0px;
  }

  &__close-btn {
    position: absolute;
    top: 8px;
    right: 12px;
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: flex;
    color: $n1;
    justify-content: center;
    align-items: center;

    i {
      font-size: 24px;
    }

    @include m {
      z-index: 15;
    }
    @include d {
      color: $white;
    }
  }

  &__body {
    padding-top: 52px;
    background: $white;
    flex: 1 1;
    overflow-y: auto;
    display: flex;
    @include d {
      background: $n1;
    }
    @include t {
      padding-top: unset;
    }
  }

  &__logo {
    background: $white;

    @include d {
      background: $n1;
    }
  }
}
