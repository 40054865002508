@import "../../../../styles/helper";

.contacy-us-container {
  .contact_header {
    background-color: #1D1E20;
  .container_logo {
      display: flex;
      width: 100%;
      padding: 12px 20px;
      align-items: center;
      justify-content: space-between;

      .brand_logo {
        width: 210px;
        height: 100%;
      }
    }

    .container_greeting {
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 300px;

      h2 {
        font-size: 40px;
        font-weight: 600;
        line-height: 56px;
        color: #D6D6D6;
      }

      p {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: #B8B8B8;
        width: auto;
        max-width: 764px;
        text-align: center;
      }
    }
  }

  .container_info{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    height: 250px;

    .info-panel{
      display: flex;
      flex-direction: column;
      gap: 4px;
      align-items: center;
      justify-content: center;
      width: 300px;
      .info-icon{
        i{
          border-radius: 7.5px;
          background-color: #1D1E20;
          padding: 10px;
          font-size: 37.5px;
        }
      }
      .info-type{
       font-size: 16px;
       font-weight: 500;
       line-height: 24px;
       color: #D6D6D6;
      }
      .info-link{
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        color: #1472FF;
        a{
          color: #1472FF;
          text-decoration: underline;
        }
      }
    }
  }
}